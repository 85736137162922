import React, { Component } from "react"
import styles from "./index.module.scss"
import Img from "gatsby-image"
import { navigate } from "gatsby"

export default class Pillar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      thumbnailHeight: 0,
    }

    this.thumbnail = null
    this.pillar = null
    this.trigger = null
  }

  componentDidMount() {
    // this.setState({ thumbnailHeight: this.thumbnail.clientWidth })

    typeof window !== "undefined" &&
      window.addEventListener("scroll", this.activatePillar)
    const { isActivePillar } = this.props
    if (isActivePillar) {
      this.pillar.classList.add(styles.active)
    }
  }

  componentDidUpdate() {
    const { isActivePillar, pillarsIsInVP } = this.props
    if (typeof window !== "undefined" && window.innerWidth <= 768) {
      if (!pillarsIsInVP && isActivePillar) {
        this.pillar.classList.add(styles.active)
      }
    } else {
      if (isActivePillar) {
        this.pillar.classList.add(styles.active)
      } else {
        this.deactivatePillars()
      }
    }
  }

  componentWillUnmount() {
    typeof window !== "undefined" &&
      window.removeEventListener("scroll", this.activatePillar)
  }

  handleMouseEnter = () => {
    if(!this.props.isOpened){
      this.props.setActivePillar(this.props.pillar)
    }
  }

  handleClick = () => {
    const { isChinese, pillar, queryString } = this.props
    if (pillar === "independence") {
      isChinese
        ? navigate("/independence" + queryString)
        : navigate("/en/independence" + queryString)
    }
    this.deactivatePillars()
    this.pillar.classList.add(styles.active)
    this.props.setActivePillar(this.props.pillar)
  }

  deactivatePillars = () => {
    this.pillar.classList.remove(styles.active)
  }

  activatePillar = () => {
    const { triggerLinePosition } = this.props
    if (typeof window !== "undefined" && window.innerWidth <= 768) {
      if (
        this.trigger.getBoundingClientRect().top <= triggerLinePosition &&
        this.trigger.getBoundingClientRect().bottom >= triggerLinePosition
      ) {
        this.pillar.classList.add(styles.active)
      } else {
        this.deactivatePillars()
      }
    }
  }

  render() {
    const {
      thumbnail,
      embrace,
      title,
      updateSlide,
      activateSlider,
      pillar,
    } = this.props
    const pillarClass =
      pillar === "progress"
        ? `${styles.progress}`
        : pillar === "wisdom"
        ? `${styles.wisdom}`
        : pillar === "freedom"
        ? `${styles.freedom}`
        : pillar === "inspiration"
        ? `${styles.inspiration}`
        : pillar === "independence"
        ? `${styles.independence}`
        : `${styles.adventure} ${styles.active}`
    return (
      <div
        id={pillar}
        className={`${styles.journey} ${pillarClass} cursor-pointer self-center md:self-auto md:flex md:relative `}
        onClick={() => {
          updateSlide(0)
          activateSlider(true)
          this.handleClick()
        }}
        onMouseEnter={this.handleMouseEnter}
        ref={pillar => (this.pillar = pillar)}
      >
        <div
          className={`mb-6 relative md:w-full md:mr-4 ${styles.pillarImgContainer}`}
          ref={thumbnail => (this.thumbnail = thumbnail)}
          // style={{ height: this.state.thumbnailHeight }}
        >
          <Img className={`${styles.pillarImg} relative`} fluid={thumbnail} />
          <div
            className="trigger top-1/2 absolute w-full h-20 transform -translate-y-1/2"
            ref={trigger => (this.trigger = trigger)}
          />
        </div>
        <h4
          className={`text-2xl uppercase text-white light-font md:self-center md:text-left md:absolute md:whitespace-no-wrap`}
        >
          {embrace}
          <br />
          <b className="bold-font text-3xl">{title}</b>
        </h4>
      </div>
    )
  }
}
