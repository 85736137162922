import React, { Component, useRef, useState } from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Slider from "react-slick"
import { useSelector, useDispatch } from "react-redux"
import ArrowCircle from "../../../../../assets/icons/Arrow-Circle.svg"
import Arrow from "../../../../Icons/Arrow"
import { initVimeoPlayer } from "../../../../utils"
class ExploreSlider extends Component {
  constructor(props) {
    super(props)
    this.slider = null
  }
  nextSlide = () => {
    this.slider.slickNext()
  }
  prevSlide = () => {
    this.slider.slickPrev()
  }
  handleClick = () => {
    alert("123")
  }

  componentDidUpdate() {
    const {
      swipeDirection,
      currentSlide,
      activePillar,
      closeLightBox,
    } = this.props
    // const numOfSlides = this.slider && this.slider.props.children.length
    const numOfSlides =
      activePillar === "progress"
        ? 7
        : activePillar === "wisdom"
        ? 5
        : activePillar === "adventure"
        ? 3
        : activePillar === "inspiration"
        ? 3
        : 1

    if (swipeDirection === "Left" && currentSlide < numOfSlides) {
      this.slider.slickNext()
    }
    if (swipeDirection === "Left" && currentSlide === numOfSlides) {
      setTimeout(() => {
        closeLightBox()
      }, 400)
    }
    if (swipeDirection === "Right") {
      this.slider.slickPrev()
    }
    console.log(activePillar)
  }
  render() {
    const {
      updateSlide,
      currentSlide,
      closeLightBox,
      activePillar,
      locale,
      queryString,
    } = this.props
    let numOfSlides =
      activePillar === "progress"
        ? 7
        : activePillar === "wisdom"
        ? 5
        : activePillar === "adventure"
        ? 3
        : activePillar === "inspiration"
        ? 3
        : 1
    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      swipe: false,
      arrows: false,
      slidesToScroll: 1,
      initialSlide: 0,
      beforeChange: (current, next) => {
        if (current > numOfSlides) {
          updateSlide(next)
          setTimeout(() => {
            closeLightBox()
          }, 400)
        } else {
          updateSlide(next)
        }
      },
      responsive: [
        {
          breakpoint: 768,
          settings: {
            centerPadding: "0px",
            slidesToShow: 1,
            arrow: false,
          },
        },
      ],
    }
    const isChinese = locale === "tc"

    return (
      <StaticQuery
        query={graphql`
          query ExploreSliderQuery {
            adventure: file(name: { eq: "Embrace Adventure" }) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            freedom: file(name: { eq: "Embrace Freedom" }) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            independence: file(name: { eq: "Embrace Independence" }) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            inspiration: file(name: { eq: "Embrace Inspiration" }) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            progress: file(name: { eq: "Embrace Progress" }) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            wisdom: file(name: { eq: "Embrace Wisdom" }) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            adventure2: file(
              name: { eq: "Latest-Stories---Embrace-Adventure-1-MOBILE" }
            ) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            adventure3: file(
              name: { eq: "Latest-Stories---Embrace-Adventure-2-MOBILE" }
            ) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            progress2: file(name: { eq: "Embrace Progress_1" }) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            progress3: file(name: { eq: "Embrace Progress_2" }) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            progress4: file(name: { eq: "Embrace Progress_3" }) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            progress5: file(name: { eq: "Embrace Progress_4-mobile" }) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            progress6: file(
              name: { eq: "BMW_90sec_EP05_Rental_cover_mobile" }
            ) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            progress7: file(name: { eq: "EP06_1to1_08" }) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            wisdom2: file(name: { eq: "Embrace Wisdom_1" }) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            wisdom3: file(name: { eq: "Embrace Wisdom_2" }) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            wisdom4: file(name: { eq: "bimmer-story-header-mobile" }) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            wisdom5: file(name: { eq: "Embrace Wisdom_3" }) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            inspiration2mobile: file(
              name: { eq: "Embrace-Inspiration_3_Mobile" }
            ) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            inspiration3mobile: file(
              name: { eq: "Embrace-Inspiration_Lululemon-YogaMobile" }
            ) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        `}
        render={data => (
          <>
            {/* <div
              className="md:hidden absolute top-0 left-0 z-10 w-full h-full"
              onClick={() => closeLightBox()}
            ></div> */}
            <div
              className={`arrow-container arrow-container--next absolute right-0 w-32 mr-12 z-10 cursor-pointer md:w-12 md:mr-6 ${
                currentSlide === numOfSlides
                  ? "opacity-0 invisible"
                  : "opacity-1 visible"
              }`}
              onClick={this.nextSlide}
            >
              <img src={ArrowCircle} alt="" className="md:hidden" />
              <div className="md:block hidden text-white">
                <Arrow />
              </div>
            </div>
            <div
              className={`arrow-container arrow-container--prev absolute left-0 w-32 ml-12 z-10 cursor-pointer md:w-12 md:ml-6  ${
                currentSlide === 0 ? "opacity-0 invisible" : "opacity-1 visible"
              }`}
              onClick={this.prevSlide}
            >
              <img src={ArrowCircle} alt="" className="md:hidden" />
              <div className="md:block hidden text-white transform rotate-180">
                <Arrow />
              </div>
            </div>
            <div
              className="cross-container xxl:p-16 absolute top-0 right-0 z-40 p-8 cursor-pointer"
              onClick={() => closeLightBox(false)}
            >
              <svg
                viewBox="0 0 612 612"
                className="w-10 text-white fill-current"
              >
                <g>
                  <g id="cross">
                    <g>
                      <polygon
                        points="612,36.004 576.521,0.603 306,270.608 35.478,0.603 0,36.004 270.522,306.011 0,575.997 35.478,611.397 
				306,341.411 576.521,611.397 612,575.997 341.459,306.011 			"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <Slider {...settings} ref={slider => (this.slider = slider)}>
              {activePillar === "adventure" && (
                <>
                  <article
                    className={`explore-slider__slide h-full flex justify-center px-10 md:px-0`}
                  >
                    <Link
                      to="/"
                      className="z-1000 md:h-full flex self-center justify-center w-full"
                    >
                      <div
                        className={`explore-slider__image-container relative w-full md:w-full md:h-full self-center`}
                      >
                        <Img
                          fluid={data.adventure.childImageSharp.fluid}
                          className="object-cover h-full"
                        />
                        <div
                          className={`explore-slider__paragraph-container mt-10 absolute h-full flex flex-col justify-between bottom-0 text-left p-8 xxl:p-16 pt-20 w-full md:pb-16`}
                        >
                          <h4 className="xxl:text-5xl md:text-5xl md:mt-16 text-white">
                            <span className="light-font">
                              {isChinese ? "心悅" : "Embrace"}
                            </span>{" "}
                            <span className="bold-font font-extrabold">
                              {isChinese ? "敢於冒險" : "Adventure"}
                            </span>
                          </h4>
                          <div className="pr-8">
                            <p
                              className={`text-xl md:text-3xl text-white light-font xxl:text-4xl mb-8`}
                            >
                              {isChinese
                                ? "敢於嘗新，敢於闖盪。一同與您的BMW開展冒險之旅，創造生活新旅途。"
                                : "Be bold, try something new. Grab your family and friends for some quality bonding time and join us in the search for adventure."}
                            </p>
                            <p
                              className={`text-xl md:text-3xl text-white light-font xxl:text-4xl`}
                            >
                              ``
                              {isChinese
                                ? "BMW推出一連串Drivecation本地駕駛旅程日記。"
                                : "Explore local Drivecations curated by us for you within Hong Kong for an enriching self- driving journey along with BMW."}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </article>
                </>
              )}
              {activePillar === "adventure" && (
                <>
                  <article
                    className={`explore-slider__slide h-full flex justify-center px-10 md:px-0`}
                  >
                    <Link
                      to={
                        isChinese
                          ? "/along-with-bmw-presents-bmw-drivecation-vol-1-the-x3-x-a-family-day-in-nature" +
                            queryString
                          : "/en/along-with-bmw-presents-bmw-drivecation-vol-1-the-x3-x-a-family-day-in-nature" +
                            queryString
                      }
                      className="z-1000 md:h-full flex self-center justify-center w-full"
                    >
                      <div
                        className={`explore-slider__image-container relative w-full md:w-full md:h-full self-center`}
                      >
                        <Img
                          fluid={data.adventure2.childImageSharp.fluid}
                          className="object-cover h-full"
                        />
                        <div
                          className={`explore-slider__paragraph-container mt-10 absolute h-full flex flex-col justify-between bottom-0 text-left p-8 xxl:p-16 pt-20 w-full md:pb-16`}
                        >
                          <h3 className="bold-font md:text-5xl xxl:text-5xl md:mt-16 text-white">
                            BMW Drivecation Vol.1:The X3 x A Family Day in
                            Nature
                          </h3>
                          <div className="pr-8">
                            <p
                              className={`text-xl md:text-3xl text-white light-font xxl:text-4xl mb-8`}
                            >
                              {isChinese
                                ? "今個夏天出行，我們將帶您與BMW X3 xDrive20iA xLine到訪香港新界西北區，親親大自然，飽覽日落美景。"
                                : "We bring you with the BMW X3 xDrive20iA xLine to visit the Northwest New Territories in Hong Kong, get close to the nature and enjoy Hong Kong’s sunset and evening views."}
                            </p>
                            <p className="md:text-3xl light-font xxl:text-4xl text-xl text-white">
                              {isChinese ? "閱讀更多 >" : "READ MORE >"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </article>
                </>
              )}
              {activePillar === "adventure" && (
                <>
                  <article
                    className={`explore-slider__slide h-full flex justify-center px-10 md:px-0`}
                  >
                    <Link
                      to="/along-with-bmw-presents-bmw-drivecation-vol-2-the-3-touring-x-a-couples-retreat-time"
                      className="z-1000 md:h-full flex self-center justify-center w-full"
                    >
                      <div
                        className={`explore-slider__image-container relative w-full md:w-full md:h-full self-center`}
                      >
                        <Img
                          fluid={data.adventure3.childImageSharp.fluid}
                          className="object-cover h-full"
                        />

                        <div
                          className={`explore-slider__paragraph-container mt-10 absolute h-full flex flex-col justify-between bottom-0 text-left p-8 xxl:p-16 pt-20 w-full md:pb-16`}
                        >
                          <h3 className="bold-font md:text-5xl xxl:text-5xl md:mt-16 text-white">
                            BMW Drivecation Vol.2: The 3 Touring x A Couples
                            Retreat Time
                          </h3>
                          <div className="pr-8">
                            <p
                              className={`text-xl md:text-3xl text-white light-font xxl:text-4xl mb-8`}
                            >
                              {isChinese
                                ? "今集 Vol.2 BMW 將帶你與THE 3 Touring 遠離城市繁嚚，一同展開一趟舒適的城中度假。"
                                : "In this episode, we connect you with the BMW THE 3 Touring and enjoys a getaway within the city."}
                            </p>
                            <p className="md:text-3xl light-font xxl:text-4xl text-xl text-white">
                              {isChinese ? "閱讀更多 >" : "READ MORE >"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </article>
                </>
              )}
              {activePillar === "adventure" && (
                <>
                  <article
                    className={`explore-slider__slide explore-slider__slide--blur h-full flex justify-center px-10 md:px-0`}
                  >
                    <div className="z-1000 md:h-full flex self-center justify-center w-full">
                      <div
                        className={`explore-slider__image-container relative w-full md:w-full md:h-full self-center z-1000`}
                      >
                        <Img
                          fluid={data.adventure.childImageSharp.fluid}
                          className="object-cover h-full"
                        />

                        <div
                          className={`explore-slider__paragraph-container mt-10 absolute h-full flex justify-center bottom-0 text-left p-8 xxl:p-16 pt-20 w-full md:pb-16`}
                        >
                          <div className="self-center text-center">
                            <div className="bold-font md:text-6xl large-text text-white uppercase">
                              {isChinese ? (
                                <h3 className="bold-font md:text-6xl large-text text-white uppercase">
                                  更多精彩內容
                                  <br />
                                  敬請期待
                                </h3>
                              ) : (
                                <h3 className="bold-font md:text-6xl large-text text-white uppercase">
                                  Coming Soon
                                </h3>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </>
              )}

              {activePillar === "progress" && (
                <>
                  <article
                    className={`explore-slider__slide h-full flex justify-center px-10 md:px-0`}
                  >
                    <Link
                      to="/"
                      className="z-1000 md:h-full flex self-center justify-center w-full"
                    >
                      <div
                        className={`explore-slider__image-container relative w-full md:w-full md:h-full self-center`}
                      >
                        <Img
                          fluid={data.progress.childImageSharp.fluid}
                          className="object-cover h-full"
                        />
                        <div
                          className={`explore-slider__paragraph-container mt-10 absolute h-full flex flex-col justify-between bottom-0 text-left p-8 xxl:p-16 pt-20 w-full md:pb-16`}
                        >
                          <h4 className="md:text-5xl xxl:text-5xl md:mt-16 text-white">
                            <span className="light-font">
                              {isChinese ? "心悅" : "Embrace"}
                            </span>{" "}
                            <span className="bold-font font-extrabold">
                              {isChinese ? "一瞬千里" : "Progress"}
                            </span>
                          </h4>
                          <div className="pr-8">
                            <p
                              className={`text-xl md:text-3xl text-white light-font xxl:text-4xl mb-8`}
                            >
                              {isChinese
                                ? "一瞬千里緊貼BMW資訊，為現在及未來做好規劃，開拓寬闊通道。"
                                : "Empower yourself with information. Remaining up-to-date on the latest opportunities available shows you the road to a brighter future."}
                            </p>
                            <p
                              className={`text-xl md:text-3xl text-white light-font xxl:text-4xl`}
                            >
                              {isChinese
                                ? "以不一樣的角度透過BMW專業人員講解一系列品牌及產品冷知識。"
                                : "Tips from our BMW Professional, to ensure that you receive the most comprehensive BMW experience"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </article>
                </>
              )}
              {activePillar === "progress" && (
                <>
                  <article
                    className={`explore-slider__slide h-full flex justify-center px-10 md:px-0`}
                  >
                    <Link
                      to={
                        isChinese
                          ? "/90-second-with-bmw-professional-bmw-finance-vol-1" +
                            queryString
                          : "/en/90-second-with-bmw-professional-bmw-finance-vol-1" +
                            queryString
                      }
                      className="z-1000 md:h-full flex self-center justify-center w-full"
                    >
                      <div
                        className={`explore-slider__image-container relative w-full md:w-full md:h-full self-center`}
                      >
                        <Img
                          fluid={data.progress2.childImageSharp.fluid}
                          className="object-cover h-full"
                        />
                        <div
                          className={`explore-slider__paragraph-container mt-10 absolute h-full flex flex-col justify-between bottom-0 text-left p-8 xxl:p-16 pt-20 w-full md:pb-16`}
                        >
                          <h3 className="bold-font md:text-5xl xxl:text-5xl md:mt-16 text-white">
                            90-Second with BMW Professional Vol.1
                          </h3>
                          <div className="pr-8">
                            <p
                              className={`text-xl md:text-3xl text-white light-font xxl:text-4xl mb-8`}
                            >
                              {isChinese
                                ? "由BMW財務專業人員讓您透過90秒時間，加深對汽車貸款方式的了解。"
                                : "We connect you with our BMW experts and allow you to deepen your understanding of Financing option in just 90- second."}
                            </p>
                            <p className="md:text-3xl light-font xxl:text-4xl text-xl text-white">
                              {isChinese ? "閱讀更多 >" : "READ MORE >"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </article>
                </>
              )}
              {activePillar === "progress" && (
                <>
                  <article
                    className={`explore-slider__slide h-full flex justify-center px-10 md:px-0`}
                  >
                    <Link
                      to={
                        isChinese
                          ? "/90-second-wth-bmw-professional-vol-2-bmw-service" +
                            queryString
                          : "/en/90-second-wth-bmw-professional-vol-2-bmw-service" +
                            queryString
                      }
                      className="z-1000 md:h-full flex self-center justify-center w-full"
                    >
                      <div
                        className={`explore-slider__image-container relative w-full md:w-full md:h-full self-center`}
                      >
                        <Img
                          fluid={data.progress3.childImageSharp.fluid}
                          className="object-cover h-full"
                        />

                        <div
                          className={`explore-slider__paragraph-container mt-10 absolute h-full flex flex-col justify-between bottom-0 text-left p-8 xxl:p-16 pt-20 w-full md:pb-16`}
                        >
                          <h3 className="bold-font md:text-5xl xxl:text-5xl md:mt-16 text-white">
                            90-Second with BMW Professional Vol.2
                          </h3>
                          <div className="pr-8">
                            <p
                              className={`text-xl md:text-3xl text-white light-font xxl:text-4xl mb-8`}
                            >
                              {isChinese
                                ? "雨季來臨，由BMW專業人員為您帶來4個在雨水出行的小提示，確保您安全在道路上行駛！"
                                : "With the rainy season approaching, our BMW aftersales experts give you 4 tips for travelling safely in the rainy days, ensuring that your vehicle are driving safe on the road."}
                            </p>
                            <p className="md:text-3xl light-font xxl:text-4xl text-xl text-white">
                              {isChinese ? "閱讀更多 >" : "READ MORE >"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </article>
                </>
              )}
              {activePillar === "progress" && (
                <>
                  <article
                    className={`explore-slider__slide h-full flex justify-center px-10 md:px-0`}
                  >
                    <Link
                      to={
                        isChinese
                          ? "/90-second-with-bmw-professional-vol-3-bmw-personal-copilot" +
                            queryString
                          : "/en/90-second-with-bmw-professional-vol-3-bmw-personal-copilot" +
                            queryString
                      }
                      className="z-1000 md:h-full flex self-center justify-center w-full"
                    >
                      <div
                        className={`explore-slider__image-container relative w-full md:w-full md:h-full self-center`}
                      >
                        <Img
                          fluid={data.progress4.childImageSharp.fluid}
                          className="object-cover h-full"
                        />

                        <div
                          className={`explore-slider__paragraph-container mt-10 absolute h-full flex flex-col justify-between bottom-0 text-left p-8 xxl:p-16 pt-20 w-full md:pb-16`}
                        >
                          <h3 className="bold-font md:text-5xl xxl:text-5xl md:mt-16 text-white">
                            90-Second with BMW Professional Vol. 3
                          </h3>
                          <div className="pr-8">
                            <p
                              className={`text-xl md:text-3xl text-white light-font xxl:text-4xl mb-8`}
                            >
                              {isChinese
                                ? "認識BMW的智能配備，了解如何於香港日常街道活用泊車輔助功能和自動倒車輔助功能，輕鬆駕馭各種情況！"
                                : "This episode focuses on BMW Personal CoPilot, allowing you to get a sense of BMW parking assistant and reversing assistant and how to use these functions in practice."}
                            </p>
                            <p className="md:text-3xl light-font xxl:text-4xl text-xl text-white">
                              {isChinese ? "閱讀更多 >" : "READ MORE >"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </article>
                </>
              )}
              {activePillar === "progress" && (
                <>
                  <article
                    className={`explore-slider__slide h-full flex justify-center px-10 md:px-0`}
                  >
                    <Link
                      to={
                        isChinese
                          ? "/90-seconds-with-bmw-professional-vol-4-bmw-intelligent-personal-assistant" +
                            queryString
                          : "/en/90-seconds-with-bmw-professional-vol-4-bmw-intelligent-personal-assistant" +
                            queryString
                      }
                      className="z-1000 md:h-full flex self-center justify-center w-full"
                    >
                      <div
                        className={`explore-slider__image-container relative w-full md:w-full md:h-full self-center`}
                      >
                        <Img
                          fluid={data.progress5.childImageSharp.fluid}
                          className="object-cover h-full"
                        />

                        <div
                          className={`explore-slider__paragraph-container mt-10 absolute h-full flex flex-col justify-between bottom-0 text-left p-8 xxl:p-16 pt-20 w-full md:pb-16`}
                        >
                          <h3 className="bold-font md:text-5xl xxl:text-5xl md:mt-16 text-white">
                            90-Second with BMW Professional Vol. 4
                          </h3>
                          <div className="pr-8">
                            <p
                              className={`text-xl md:text-3xl text-white light-font xxl:text-4xl mb-8`}
                            >
                              {isChinese
                                ? "了解 BMW Intelligent Personal Assistant 智慧型語音助理及Car Caring function座艙關懷程式如何支援行車常用指令！"
                                : "Learn about how BMW Intelligent Personal Assistant and Car Caring function can support your daily driving instructions!"}
                            </p>
                            <p className="md:text-3xl light-font xxl:text-4xl text-xl text-white">
                              {isChinese ? "閱讀更多 >" : "READ MORE >"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </article>
                </>
              )}
              {activePillar === "progress" && (
                <>
                  <article
                    className={`explore-slider__slide h-full flex justify-center px-10 md:px-0`}
                  >
                    <Link
                      to={
                        isChinese
                          ? "/90-seconds-with-bmw-professional-vol-5-bmw-rental-service" +
                            queryString
                          : "/en/90-seconds-with-bmw-professional-vol-5-bmw-rental-service" +
                            queryString
                      }
                      className="z-1000 md:h-full flex self-center justify-center w-full"
                    >
                      <div
                        className={`explore-slider__image-container relative w-full md:w-full md:h-full self-center`}
                      >
                        <Img
                          fluid={data.progress6.childImageSharp.fluid}
                          className="object-cover h-full"
                        />

                        <div
                          className={`explore-slider__paragraph-container mt-10 absolute h-full flex flex-col justify-between bottom-0 text-left p-8 xxl:p-16 pt-20 w-full md:pb-16`}
                        >
                          <h3 className="bold-font md:text-5xl xxl:text-5xl md:mt-16 text-white">
                            90-Second with BMW Professional Vol. 5 Rental
                            Service
                          </h3>
                          <div className="pr-8">
                            <p
                              className={`text-xl md:text-3xl text-white light-font xxl:text-4xl mb-8`}
                            >
                              {isChinese
                                ? "你有沒有想過在香港也可以租車? 今集以 BMW Rental Service為主題，讓你了解更多有關短期和長期租賃的服務。"
                                : "Have you ever thought of renting a car in Hong Kong? In episode 5, we introduce you about the BMW Rental Service."}
                            </p>
                            <p className="md:text-3xl light-font xxl:text-4xl text-xl text-white">
                              {isChinese ? "閱讀更多 >" : "READ MORE >"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </article>
                </>
              )}
              {activePillar === "progress" && (
                <>
                  <article
                    className={`explore-slider__slide h-full flex justify-center px-10 md:px-0`}
                  >
                    <Link
                      to={
                        isChinese
                          ? "/90-seconds-with-bmw-professional-vol-6-bmw-service" +
                            queryString
                          : "/en/90-seconds-with-bmw-professional-vol-6-bmw-service" +
                            queryString
                      }
                      className="z-1000 md:h-full flex self-center justify-center w-full"
                    >
                      <div
                        className={`explore-slider__image-container relative w-full md:w-full md:h-full self-center`}
                      >
                        <Img
                          fluid={data.progress7.childImageSharp.fluid}
                          className="object-cover h-full"
                        />

                        <div
                          className={`explore-slider__paragraph-container mt-10 absolute h-full flex flex-col justify-between bottom-0 text-left p-8 xxl:p-16 pt-20 w-full md:pb-16`}
                        >
                          <h3 className="bold-font md:text-5xl xxl:text-5xl md:mt-16 text-white">
                          90-Seconds with BMW Professional Vol. 6: BMW Service
                          </h3>
                          <div className="pr-8">
                            <p
                              className={`text-xl md:text-3xl text-white light-font xxl:text-4xl mb-8`}
                            >
                              {isChinese
                                ? "在第６集BMW Service以定期保養為課題，讓你了解更多BMW智慧保養系統的定期保養提示。"
                                : "Learn more about BMW Condition Based Service System and the importance of Regular Maintenance."}
                            </p>
                            <p className="md:text-3xl light-font xxl:text-4xl text-xl text-white">
                              {isChinese ? "閱讀更多 >" : "READ MORE >"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </article>
                </>
              )}
              {activePillar === "progress" && (
                <>
                  <article
                    className={`explore-slider__slide explore-slider__slide--blur h-full flex justify-center px-10 md:px-0`}
                  >
                    <div className="z-1000 md:h-full flex self-center justify-center w-full">
                      <div
                        className={`explore-slider__image-container relative w-full md:w-full md:h-full self-center z-1000`}
                      >
                        <Img
                          fluid={data.progress.childImageSharp.fluid}
                          className="object-cover h-full"
                        />

                        <div
                          className={`explore-slider__paragraph-container mt-10 absolute h-full flex justify-center bottom-0 text-left p-8 xxl:p-16 pt-20 w-full md:pb-16`}
                        >
                          <div className="self-center text-center">
                            <div className="bold-font md:text-6xl large-text text-white uppercase">
                              {isChinese ? (
                                <h3 className="bold-font md:text-6xl large-text text-white uppercase">
                                  更多精彩內容
                                  <br />
                                  敬請期待
                                </h3>
                              ) : (
                                <h3 className="bold-font md:text-6xl large-text text-white uppercase">
                                  Coming Soon
                                </h3>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </>
              )}

              {activePillar === "freedom" && (
                <>
                  <article
                    className={`explore-slider__slide h-full flex justify-center px-10 md:px-0`}
                  >
                    <Link
                      to="/"
                      className="z-1000 md:h-full flex self-center justify-center w-full"
                    >
                      <div
                        className={`explore-slider__image-container relative w-full md:w-full md:h-full self-center`}
                      >
                        <Img
                          fluid={data.freedom.childImageSharp.fluid}
                          className="object-cover h-full"
                        />
                        <div
                          className={`explore-slider__paragraph-container mt-10 absolute h-full flex flex-col justify-between bottom-0 text-left p-8 xxl:p-16 pt-20 w-full md:pb-16`}
                        >
                          <h4 className="md:text-5xl xxl:text-5xl md:mt-16 text-white">
                            <span className="light-font">
                              {isChinese ? "心悅" : "Embrace"}
                            </span>{" "}
                            <span className="bold-font font-extrabold">
                              {isChinese ? "遨翔自得" : "Freedom"}
                            </span>
                          </h4>
                          <div className="pr-8">
                            <p
                              className={`text-xl md:text-3xl text-white light-font xxl:text-4xl mb-8`}
                            >
                              {isChinese
                                ? "由喜悅導航，BMW為您擺脫束縛，自由飛馳享受生活。"
                                : "Freedom invigorates the senses; it is an opportunity for self-reflection and opens up the road to finding your inner-joy."}
                            </p>
                            <p
                              className={`text-xl md:text-3xl text-white light-font xxl:text-4xl`}
                            >
                              {isChinese
                                ? "BMW帶您品嚐本地美食，亦會誠邀車主參加本地自駕遊。"
                                : "BMW invites you to invigorate your palette and join us on a series of self-driving tours to explore and rediscover delicious local and international cuisine."}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </article>
                </>
              )}
              {activePillar === "freedom" && (
                <>
                  <article
                    className={`explore-slider__slide explore-slider__slide--blur h-full flex justify-center px-10 md:px-0`}
                  >
                    <div className="z-1000 md:h-full flex self-center justify-center w-full">
                      <div
                        className={`explore-slider__image-container relative w-full md:w-full md:h-full self-center z-1000`}
                      >
                        <Img
                          fluid={data.freedom.childImageSharp.fluid}
                          className="object-cover h-full"
                        />

                        <div
                          className={`explore-slider__paragraph-container mt-10 absolute h-full flex justify-center bottom-0 text-left p-8 xxl:p-16 pt-20 w-full md:pb-16`}
                        >
                          <div className="self-center text-center">
                            <div className="bold-font md:text-6xl large-text text-white uppercase">
                              {isChinese ? (
                                <h3 className="bold-font md:text-6xl large-text text-white uppercase">
                                  更多精彩內容
                                  <br />
                                  敬請期待
                                </h3>
                              ) : (
                                <h3 className="bold-font md:text-6xl large-text text-white uppercase">
                                  Coming Soon
                                </h3>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </>
              )}

              {activePillar === "inspiration" && (
                <>
                  <article
                    className={`explore-slider__slide h-full flex justify-center px-10 md:px-0`}
                  >
                    <Link
                      to="/"
                      className="z-1000 md:h-full flex self-center justify-center w-full"
                    >
                      <div
                        className={`explore-slider__image-container relative w-full md:w-full md:h-full self-center`}
                      >
                        <Img
                          fluid={data.inspiration.childImageSharp.fluid}
                          className="object-cover h-full"
                        />
                        <div
                          className={`explore-slider__paragraph-container mt-10 absolute h-full flex flex-col justify-between bottom-0 text-left p-8 xxl:p-16 pt-20 w-full md:pb-16`}
                        >
                          <h4 className="md:text-5xl xxl:text-5xl md:mt-16 text-white">
                            <span className="light-font">
                              {isChinese ? "心悅" : "Embrace"}
                            </span>{" "}
                            <span className="bold-font font-extrabold">
                              {isChinese ? "啟迪靈感" : "Inspiration"}
                            </span>
                          </h4>
                          <div className="pr-8">
                            <p
                              className={`text-xl md:text-3xl text-white light-font xxl:text-4xl mb-8`}
                            >
                              {isChinese
                                ? "善待自己，預留專屬個人時刻，尋回恬靜內心。"
                                : "Be kind to yourself; take time for self-care and find your serenity. Join us on a journey of health and wellness."}
                            </p>
                            <p
                              className={`text-xl md:text-3xl text-white light-font xxl:text-4xl`}
                            >
                              {isChinese
                                ? "發掘一系列BMWINSPIRE「身、心、靈」全方位體驗。"
                                : "Discover a series of “Stay Active”, “Retreat” and “Wellness” well-rounded experiences with BMW INSPIRE."}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </article>
                </>
              )}
              {activePillar === "inspiration" && (
                <>
                  <article
                    className={`explore-slider__slide h-full flex justify-center px-10 md:px-0`}
                  >
                    <Link
                      to={
                        isChinese
                          ? "/bmw-inspire-empower-your-journey-music-live" +
                            queryString
                          : "/en/bmw-inspire-empower-your-journey-music-live" +
                            queryString
                      }
                      // href="https://www.facebook.com/MOOVHK/videos/417601339206654"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="z-1000 md:h-full flex self-center justify-center w-full"
                    >
                      <div
                        className={`explore-slider__image-container relative w-full md:w-full md:h-full self-center`}
                      >
                        <Img
                          fluid={data.inspiration2mobile.childImageSharp.fluid}
                          className="object-cover h-full"
                        />
                        <div
                          className={`explore-slider__paragraph-container mt-10 absolute h-full flex flex-col justify-between bottom-0 text-left p-8 xxl:p-16 pt-20 w-full md:pb-16`}
                        >
                          <h4 className="md:text-5xl xxl:text-5xl md:mt-16 bold-font font-extrabold text-white">
                            {/* {isChinese
                              ? "立即重溫MOOV x BMW Empower Your Journey Music Live"
                              : "Rewatch MOOV x BMW Empower Your Journey Music Live"} */}
                            {isChinese
                              ? "BMW INSPIRE: Empower Your Journey 音樂欣賞"
                              : "BMW INSPIRE: Empower Your Journey with Music"}
                          </h4>
                          <div className="pr-8">
                            <p className="md:text-3xl light-font xxl:text-4xl text-xl text-white uppercase">
                              {isChinese ? "了解更多 >" : "Learn More >"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </article>
                </>
              )}
              {activePillar === "inspiration" && (
                <article
                  className={`explore-slider__slide h-full flex justify-center px-10 md:px-0`}
                >
                  <a href="https://www.youtube.com/watch?v=fv5JnN4IYok" className="z-1000 md:h-full flex self-center justify-center w-full" target="_blank" rel="noopener noreferrer">
                    <div
                      className={`explore-slider__image-container relative w-full md:w-full md:h-full self-center`}
                    >
                      <Img
                        fluid={data.inspiration3mobile.childImageSharp.fluid}
                        className="object-cover h-full"
                      />
                      <div
                        className={`explore-slider__paragraph-container mt-10 absolute h-full flex flex-col justify-between bottom-0 text-left p-8 xxl:p-16 pt-20 w-full md:pb-16`}
                      >
                        <h4 className="md:text-5xl xxl:text-5xl md:mt-16 bold-font font-extrabold text-white">
                          BMW INSPIRE:
                          <br />
                          Relaxing Yoga Stretches
                        </h4>
                        <div className="pr-8">
                          <p
                            className={`text-xl md:text-3xl text-white light-font xxl:text-4xl mb-8`}
                          >
                            {isChinese
                              ? "利用15分鐘時間，與我們一起做瑜伽，放鬆身心，釋放壓力，排出負面情緒。"
                              : "Spend 15-minute with us for a relaxing yoga session. Calm your mind and relax the body."}
                          </p>
                          {isChinese ? null : (
                            <p
                              className={`text-lg md:text-xl text-white light-font xxl:text-2xl mb-8`}
                            >
                              <sup>*</sup>Conducted in Cantonese only.
                            </p>
                          )}
                          <p className="md:text-3xl light-font xxl:text-4xl text-xl text-white uppercase">
                            {isChinese ? "了解更多 >" : "Learn More >"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                </article>
                // <VideoModal data={data} isChinese={isChinese} />
              )}
              {activePillar === "inspiration" && (
                <>
                  <article
                    className={`explore-slider__slide explore-slider__slide--blur h-full flex justify-center px-10 md:px-0`}
                  >
                    <div className="z-1000 md:h-full flex self-center justify-center w-full">
                      <div
                        className={`explore-slider__image-container relative w-full md:w-full md:h-full self-center z-1000`}
                      >
                        <Img
                          fluid={data.inspiration.childImageSharp.fluid}
                          className="object-cover h-full"
                        />

                        <div
                          className={`explore-slider__paragraph-container mt-10 absolute h-full flex justify-center bottom-0 text-left p-8 xxl:p-16 pt-20 w-full md:pb-16`}
                        >
                          <div className="self-center text-center">
                            <div className="bold-font md:text-6xl large-text text-white uppercase">
                              {isChinese ? (
                                <h3 className="bold-font md:text-6xl large-text text-white uppercase">
                                  更多精彩內容
                                  <br />
                                  敬請期待
                                </h3>
                              ) : (
                                <h3 className="bold-font md:text-6xl large-text text-white uppercase">
                                  Coming Soon
                                </h3>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </>
              )}

              {activePillar === "wisdom" && (
                <>
                  <article
                    className={`explore-slider__slide h-full flex justify-center px-10 md:px-0`}
                  >
                    <Link
                      to="/"
                      className="z-1000 md:h-full flex self-center justify-center w-full"
                    >
                      <div
                        className={`explore-slider__image-container relative w-full md:w-full md:h-full self-center`}
                      >
                        <Img
                          fluid={data.wisdom.childImageSharp.fluid}
                          className="object-cover h-full"
                        />
                        <div
                          className={`explore-slider__paragraph-container mt-10 absolute h-full flex flex-col justify-between bottom-0 text-left p-8 xxl:p-16 pt-20 w-full md:pb-16`}
                        >
                          <h4 className="bold-font md:text-5xl xxl:text-5xl md:mt-16 text-white">
                            <span className="light-font">
                              {isChinese ? "心悅" : "Embrace"}
                            </span>{" "}
                            <span className="bold-font font-extrabold">
                              {isChinese ? "遠見卓識" : "Wisdom"}
                            </span>
                          </h4>
                          <div className="pr-8">
                            <p
                              className={`text-xl md:text-3xl text-white light-font xxl:text-4xl mb-8`}
                            >
                              {isChinese
                                ? "細閱日常經驗，重新釐定角度，培育遠見卓識。"
                                : "Find perspective to ground your soul. Check back for enlightening stories from BMW owners to nourish your wisdom."}
                            </p>
                            <p
                              className={`text-xl md:text-3xl text-white light-font xxl:text-4xl`}
                            >
                              {isChinese
                                ? "全新BMW及BMW車主故事帶您進入與別不同的寶馬世界。"
                                : " New BMW stories to enrich your journey; inspiring experience from BMW owners show you just how varied life with your BMW can be."}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </article>
                </>
              )}
              {activePillar === "wisdom" && (
                <>
                  <VideoCard
                    isChinese={isChinese}
                    data={data}
                    queryString={queryString}
                  />
                </>
              )}
              {activePillar === "wisdom" && (
                <>
                  <article
                    className={`explore-slider__slide h-full flex justify-center px-10 md:px-0`}
                  >
                    <Link
                      to={
                        isChinese
                          ? "/the-progressive-new-bmw-logo-design" + queryString
                          : "/en/the-progressive-new-bmw-logo-design" +
                            queryString
                      }
                      className="z-1000 md:h-full flex self-center justify-center w-full"
                    >
                      <div
                        className={`explore-slider__image-container relative w-full md:w-full md:h-full self-center`}
                      >
                        <Img
                          fluid={data.wisdom3.childImageSharp.fluid}
                          className="object-cover h-full"
                        />

                        <div
                          className={`explore-slider__paragraph-container mt-10 absolute h-full flex flex-col justify-between bottom-0 text-left p-8 xxl:p-16 pt-20 w-full md:pb-16`}
                        >
                          <h3 className="bold-font md:text-5xl xxl:text-5xl md:mt-16 text-white">
                            {isChinese
                              ? "BMW全新品牌形象"
                              : " The Progressive New BMW Logo Design"}
                          </h3>
                          <div className="pr-8">
                            <p
                              className={`text-xl md:text-3xl text-white light-font xxl:text-4xl mb-8`}
                            >
                              {isChinese
                                ? "「這不僅僅是設計上的更新，更代表BMW往後的理念—成為更加側重用戶關係的品牌。新標誌代表著更大的開放性及清晰度。」"
                                : "“BMW becomes a relationship brand. The newcommunication logo radiates openness and clarity.“"}
                            </p>
                            <p className="md:text-3xl light-font xxl:text-4xl text-xl text-white">
                              {isChinese ? "閱讀更多 >" : "READ MORE >"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </article>
                </>
              )}
              {activePillar === "wisdom" && (
                <>
                  <article
                    className={`explore-slider__slide h-full flex justify-center px-10 md:px-0`}
                  >
                    <Link
                      to={
                        isChinese
                          ? "/bimmer-story-a-dream-journey-awb-story-writing-competition-winners-submissions" +
                            queryString
                          : "/en/bimmer-story-a-dream-journey-awb-story-writing-competition-winners-submissions" +
                            queryString
                      }
                      className="relative flex w-full h-full"
                    >
                      <div
                        className={`explore-slider__image-container relative w-full md:w-full md:h-full self-center`}
                      >
                        <Img
                          fluid={data.wisdom4.childImageSharp.fluid}
                          className="object-cover h-full"
                        />

                        <div
                          className={`explore-slider__paragraph-container mt-10 absolute h-full flex flex-col justify-between bottom-0 text-left p-8 xxl:p-16 pt-20 w-full md:pb-16`}
                        >
                          <h3 className="bold-font md:text-5xl xxl:text-5xl md:mt-16 text-white">
                            {isChinese
                              ? "BMW「心悅旅程」徵文比賽精選作品"
                              : "A Dream Journey ALONG with BMW Story-Writing Competition Selected Stories"}
                          </h3>
                          <div className="pr-8">
                            <p
                              className={`text-xl md:text-3xl text-white light-font xxl:text-4xl mb-8`}
                            >
                              {isChinese
                                ? "我們希望發掘並分享一系列BMW與生活同行的每種時刻。無論您現在身在何處，您的故事都值得被分享。"
                                : "We hope to build our inspiring collection of stories and we believe YOU have a unique story to share."}
                            </p>
                            <p className="md:text-3xl light-font xxl:text-4xl text-xl text-white">
                              {isChinese ? "閱讀更多 >" : "READ MORE >"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </article>
                </>
              )}
              {activePillar === "wisdom" && (
                <article
                  className={`explore-slider__slide h-full flex justify-center px-10 md:px-0`}
                >
                  <Link
                    to={
                      isChinese
                        ? "/drawing-a-bmw-how-to-sketch-your-dream-car" +
                          queryString
                        : "/en/drawing-a-bmw-how-to-sketch-your-dream-car" +
                          queryString
                    }
                    className="relative flex w-full h-full"
                  >
                    <div
                      className={`explore-slider__image-container relative w-full md:w-full md:h-full self-center`}
                    >
                      <Img
                        fluid={data.wisdom5.childImageSharp.fluid}
                        className="object-cover h-full"
                      />

                      <div
                        className={`explore-slider__paragraph-container mt-10 absolute h-full flex flex-col justify-between bottom-0 text-left p-8 xxl:p-16 pt-20 w-full md:pb-16`}
                      >
                        <h3 className="bold-font md:text-5xl xxl:text-5xl md:mt-16 text-white capitalize">
                          {isChinese
                            ? "如何繪畫出您的BMW夢想汽車"
                            : "How to draw your Dream BMW"}
                        </h3>
                        <div className="pr-8">
                          <p
                            className={`text-xl md:text-3xl text-white light-font xxl:text-4xl mb-8`}
                          >
                            {isChinese
                              ? "逐步繪畫汽車草圖 – 我們會為您揭示BMW汽車設計師的繪畫技巧，即使是初學者也可輕鬆地繪畫出汽車草圖，並可勾勒出他們的BMW夢想汽車!"
                              : "Car drawing step by step – we reveal the tips from BMW car designers, even a beginner can do an easy car sketch and draw their Dream BMW car!"}
                          </p>
                          <p className="md:text-3xl light-font xxl:text-4xl text-xl text-white">
                            {isChinese ? "閱讀更多 >" : "READ MORE >"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </article>
              )}
              {activePillar === "wisdom" && (
                <>
                  <article
                    className={`explore-slider__slide explore-slider__slide--blur h-full flex justify-center px-10 md:px-0`}
                  >
                    <div className="z-1000 md:h-full flex self-center justify-center w-full">
                      <div
                        className={`explore-slider__image-container relative w-full md:w-full md:h-full self-center z-1000`}
                      >
                        <Img
                          fluid={data.wisdom.childImageSharp.fluid}
                          className="object-cover h-full"
                        />

                        <div
                          className={`explore-slider__paragraph-container mt-10 absolute h-full flex justify-center bottom-0 text-left p-8 xxl:p-16 pt-20 w-full md:pb-16`}
                        >
                          <div className="self-center text-center">
                            {isChinese ? (
                              <h3 className="bold-font md:text-6xl large-text text-white uppercase">
                                更多精彩內容
                                <br />
                                敬請期待
                              </h3>
                            ) : (
                              <h3 className="bold-font md:text-6xl large-text text-white uppercase">
                                Coming Soon
                              </h3>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </>
              )}

              {/* <article
                className={`explore-slider__slide h-full flex justify-center px-10 md:px-0 md:hidden`}
              ></article>
              <article
                className={`explore-slider__slide h-full flex justify-center px-10 md:px-0 md:hidden`}
              ></article> */}
            </Slider>
          </>
        )}
      />
    )
  }
}

export default ExploreSlider

class VideoCard extends Component {
  constructor(props) {
    super(props)
    this.video = null
  }

  componentDidMount() {
    initVimeoPlayer({
      selector: this.video,
      desktopVid: "https://vimeo.com/440594849/7c94a597ec",
      mobileVid: null,
    })
  }

  componentDidUpdate() {}
  render() {
    const { data, isChinese, queryString } = this.props
    return (
      <article
        className={`explore-slider__slide h-full flex justify-center px-10 md:px-0`}
      >
        <Link
          to={
            isChinese
              ? "/10-bmw-questions-for-you" + queryString
              : "/en/10-bmw-questions-for-you" + queryString
          }
          className="z-1000 md:h-full flex self-center justify-center w-full"
        >
          <div
            className={`explore-slider__image-container relative w-full md:w-full md:h-full self-center`}
          >
            <Img
              fluid={data.wisdom3.childImageSharp.fluid}
              className="object-cover h-full opacity-0"
            />
            <div
              ref={video => (this.video = video)}
              className="video-iframe-container top-1/2 left-1/2 absolute w-full h-full transform -translate-x-1/2 -translate-y-1/2 bg-black"
            ></div>
            <div
              className={`explore-slider__paragraph-container mt-10 absolute h-full flex flex-col justify-between bottom-0 text-left p-8 xxl:p-16 pt-20 w-full md:pb-16`}
            >
              <h3 className="bold-font md:text-5xl xxl:text-5xl md:mt-16 text-white">
                {isChinese
                  ? "10條問題，測試你對BMW的認識有多深?"
                  : " 10 BMW Questions For You: How well do you know BMW?"}
              </h3>
              <div className="pr-8">
                <p
                  className={`text-xl md:text-3xl text-white light-font xxl:text-4xl mb-8`}
                >
                  {isChinese
                    ? "立即挑戰下列10條有關BMW的問題，看看你對BMW有多認識！"
                    : " Take the 10-question challenge below now!"}
                </p>
                <p className="md:text-3xl light-font xxl:text-4xl text-xl text-white">
                  {isChinese ? "閱讀更多 >" : "READ MORE >"}
                </p>
              </div>
            </div>
          </div>
        </Link>
      </article>
    )
  }
}

function VideoModal({ data, isChinese }) {
  const dispatch = useDispatch()
  return (
    <article
      className={`explore-slider__slide h-full flex justify-center px-10 md:px-0`}
      onClick={() =>
        dispatch({
          type: "TOGGLE_VIDEO_MODAL",
          opened: true,
        })
      }
    >
      <div className="z-1000 md:h-full flex self-center justify-center w-full">
        <div
          className={`explore-slider__image-container relative w-full md:w-full md:h-full self-center`}
        >
          <Img
            fluid={data.inspiration3mobile.childImageSharp.fluid}
            className="object-cover h-full"
          />
          <div
            className={`explore-slider__paragraph-container mt-10 absolute h-full flex flex-col justify-between bottom-0 text-left p-8 xxl:p-16 pt-20 w-full md:pb-16`}
          >
            <h4 className="md:text-5xl xxl:text-5xl md:mt-16 bold-font font-extrabold text-white">
              BMW INSPIRE:
              <br />
              Relaxing Yoga Stretches
            </h4>
            <div className="pr-8">
              <p
                className={`text-xl md:text-3xl text-white light-font xxl:text-4xl mb-8`}
              >
                {isChinese
                  ? "利用15分鐘時間，與我們一起做瑜伽，放鬆身心，釋放壓力，排出負面情緒。"
                  : "Spend 15-minute with us for a relaxing yoga session. Calm your mind and relax the body."}
              </p>
              {isChinese ? null : (
                <p
                  className={`text-lg md:text-xl text-white light-font xxl:text-2xl mb-8`}
                >
                  <sup>*</sup>Conducted in Cantonese only.
                </p>
              )}
              <p className="md:text-3xl light-font xxl:text-4xl text-xl text-white uppercase">
                {isChinese ? "了解更多 >" : "Learn More >"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </article>
  )
}
