import React, { Component } from "react"
import styles from "./index.module.scss"
import { StaticQuery, graphql } from "gatsby"
import Fade from "react-reveal/Fade"
import ExploreSlider from "./ExploreSlider"
import { Swipeable } from "react-swipeable"
import { connect } from "react-redux"
import {
  setActivePillar,
  activateSlider,
  updateSlide,
} from "../../../../state/actions"
import Pillar from "./Pillar"

class Explore extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpened: false,
      currentSlide: 0,
      isMobile: true,
      swipeDirection: null,
      articleHeight: 0,
      pillarsIsOnHovered: false,
      activePillar: "",
      triggerLinePosition: 0,
      pillarsIsInVP: false,
    }

    this.trigger = null
    this.container = null
  }

  componentDidMount() {
    if (typeof window !== "undefined" && window.innerWidth <= 768) {
      window.addEventListener("scroll", this.setTriggerLinePosition)
      window.addEventListener("scroll", this.setTriggerInVP)
    }
    const { activateSlider } = this.props
    activateSlider(false)
  }

  componentWillUnmount() {
    if (typeof window !== "undefined" && window.innerWidth <= 768) {
      window.removeEventListener("scroll", this.setTriggerLinePosition)
      window.removeEventListener("scroll", this.setTriggerInVP)
    }
  }

  // setActivePillar = pillar => {
  //   this.setState({ activePillar: pillar })
  // }

  openLightBox = i => {
    this.setState({ isOpened: true })
    this.setState({ currentSlide: i || 0 })
  }

  closeLightBox = () => {
    this.setState({ isOpened: false })
  }

  updateSlide = i => {
    this.setState({ currentSlide: i })
  }

  trackSwipeDirection = direction => {
    this.setState({ swipeDirection: direction })
  }

  handleMouseEnter = () => {
    this.setState({ pillarsIsOnHovered: true })
  }

  handleMouseLeave = () => {
    this.setState({ pillarsIsOnHovered: false })
  }

  setTriggerLinePosition = () => {
    if (
      this.trigger &&
      typeof window !== "undefined" &&
      window.innerWidth <= 768
    ) {
      this.setState({ triggerLinePosition: this.trigger.offsetTop })
    }
  }

  setTriggerInVP = () => {
    if (
      this.container &&
      typeof window !== "undefined" &&
      this.state.triggerLinePosition !== 0 &&
      window.innerWidth <= 768
    ) {
      if (
        this.container.getBoundingClientRect().top <=
        this.state.triggerLinePosition
      ) {
        this.setState({ pillarsIsInVP: true })
      } else if (
        this.container.getBoundingClientRect().bottom <=
        this.state.triggerLinePosition
      ) {
        this.setState({ pillarsIsInVP: false })
        alert("123")
      } else {
        this.setState({ pillarsIsInVP: false })
      }
    }
  }

  render() {
    const {
      swipeDirection,
      pillarsIsOnHovered,
      triggerLinePosition,
      pillarsIsInVP,
    } = this.state
    const {
      home,
      pillar: { activePillar, isOpened, currentSlide },
      setActivePillar,
      updateSlide,
      activateSlider,
      URL,
    } = this.props
    const { locale } = this.props.lang
    const isChinese = locale === "tc"

    const config = {
      delta: 10,
      preventDefaultTouchmoveEvent: false,
      trackTouch: true,
      trackMouse: false,
      rotationAngle: 0,
    }
    const queryString = URL.queryString ? URL.queryString : ""
    return (
      <section
        className="section-home-explore bg-brand-grey xxl:py-56 md:pt-20 md:flex-col md:h-auto md:pb-20 md:border-b-2 md:border-white md:border-solid relative flex py-20 overflow-hidden"
        ref={container => (this.container = container)}
      >
        <div
          className="trigger top-1/2 fixed w-full h-1 transform translate-y-1/2"
          ref={trigger => (this.trigger = trigger)}
        />
        <div className="flex justify-center w-full">
          <div className="w-10/12 text-center">
            <Fade bottom>
              <h3 className="headline light-font mb-16 text-white uppercase">
                {/* {home.exploreSectionTitle} */}
                {isChinese ? "探索更多" : "Explore More Journeys With Us"}
              </h3>
            </Fade>
            <Fade bottom>
              <p className="large-text light-font md:mb-48 xxl:mb-40 mb-24 text-white">
                {isChinese
                  ? "了解更多有關BMW Drivecation本地駕駛旅程日記、全新BMW全方位體驗、獨家BMW車主故事、品牌及產品冷知識等。"
                  : "Learn more about the BMW Hong Kong Drivecation series, exciting BMW well-rounded experiences, BMW-owner journeys, as well as tips from our BMW experts to ensure that you get the most out of life with BMW."}
              </p>
            </Fade>
            <Fade>
              <div
                className={`${
                  styles.journeyContainer
                } flex flex-wrap justify-center md:w-8/12 md:px-10 ${
                  locale === "tc" ? styles.isChinese : ""
                }`}
                role="button"
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
              >
                <StaticQuery
                  query={graphql`
                    query ExploreQuery {
                      adventure: file(
                        name: { eq: "Embrace Adventure Square" }
                      ) {
                        childImageSharp {
                          fluid {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                      freedom: file(name: { eq: "Embrace Freedom Square" }) {
                        childImageSharp {
                          fluid {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                      independence: file(
                        name: { eq: "Embrace Independence Square" }
                      ) {
                        childImageSharp {
                          fluid {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                      inspiration: file(
                        name: { eq: "Embrace Inspiration Square" }
                      ) {
                        childImageSharp {
                          fluid {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                      progress: file(name: { eq: "Embrace Progress Square" }) {
                        childImageSharp {
                          fluid {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                      wisdom: file(name: { eq: "Embrace Wisdom Square" }) {
                        childImageSharp {
                          fluid {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                  `}
                  render={data => (
                    <>
                      <Pillar
                        thumbnail={data.freedom.childImageSharp.fluid}
                        embrace={home.embrace}
                        title={home.freedomTitle}
                        updateSlide={updateSlide}
                        activateSlider={activateSlider}
                        pillar="freedom"
                        pillarsIsOnHovered={pillarsIsOnHovered}
                        isActivePillar={activePillar === "freedom"}
                        triggerLinePosition={triggerLinePosition}
                        pillarsIsInVP={pillarsIsInVP}
                        key={home.freedomTitle}
                        setActivePillar={setActivePillar}
                        isChinese={isChinese}
                        queryString={queryString}
                        isOpened={isOpened}
                      />
                      <Pillar
                        thumbnail={data.progress.childImageSharp.fluid}
                        embrace={home.embrace}
                        title={home.progressTitle}
                        updateSlide={updateSlide}
                        activateSlider={activateSlider}
                        pillar="progress"
                        pillarsIsOnHovered={pillarsIsOnHovered}
                        isActivePillar={activePillar === "progress"}
                        triggerLinePosition={triggerLinePosition}
                        pillarsIsInVP={pillarsIsInVP}
                        key={home.progressTitle}
                        setActivePillar={setActivePillar}
                        isChinese={isChinese}
                        queryString={queryString}
                        isOpened={isOpened}
                      />
                      <Pillar
                        thumbnail={data.adventure.childImageSharp.fluid}
                        embrace={home.embrace}
                        title={home.adventureTitle}
                        updateSlide={updateSlide}
                        activateSlider={activateSlider}
                        pillar="adventure"
                        pillarsIsOnHovered={pillarsIsOnHovered}
                        isActivePillar={activePillar === "adventure"}
                        triggerLinePosition={triggerLinePosition}
                        pillarsIsInVP={pillarsIsInVP}
                        key={home.adventureTitle}
                        setActivePillar={setActivePillar}
                        isChinese={isChinese}
                        queryString={queryString}
                        isOpened={isOpened}
                      />
                      <Pillar
                        thumbnail={data.wisdom.childImageSharp.fluid}
                        embrace={home.embrace}
                        title={home.wisdomTitle}
                        updateSlide={updateSlide}
                        activateSlider={activateSlider}
                        pillar="wisdom"
                        pillarsIsOnHovered={pillarsIsOnHovered}
                        isActivePillar={activePillar === "wisdom"}
                        triggerLinePosition={triggerLinePosition}
                        pillarsIsInVP={pillarsIsInVP}
                        key={home.wisdomTitle}
                        setActivePillar={setActivePillar}
                        isChinese={isChinese}
                        queryString={queryString}
                        isOpened={isOpened}
                      />
                      <Pillar
                        thumbnail={data.inspiration.childImageSharp.fluid}
                        embrace={home.embrace}
                        title={home.inspirationTitle}
                        updateSlide={updateSlide}
                        activateSlider={activateSlider}
                        pillar="inspiration"
                        pillarsIsOnHovered={pillarsIsOnHovered}
                        isActivePillar={activePillar === "inspiration"}
                        triggerLinePosition={triggerLinePosition}
                        pillarsIsInVP={pillarsIsInVP}
                        key={home.inspirationTitle}
                        setActivePillar={setActivePillar}
                        isChinese={isChinese}
                        queryString={queryString}
                        isOpened={isOpened}
                      />
                      <Pillar
                        thumbnail={data.independence.childImageSharp.fluid}
                        embrace={home.embrace}
                        title={home.independenceTitle}
                        updateSlide={updateSlide}
                        activateSlider={activateSlider}
                        pillar="independence"
                        pillarsIsOnHovered={pillarsIsOnHovered}
                        isActivePillar={activePillar === "independence"}
                        triggerLinePosition={triggerLinePosition}
                        pillarsIsInVP={pillarsIsInVP}
                        key={home.independenceTitle}
                        setActivePillar={setActivePillar}
                        isChinese={isChinese}
                        queryString={queryString}
                        isOpened={isOpened}
                      />
                    </>
                  )}
                />
              </div>
            </Fade>
          </div>
        </div>
        {console.log(activePillar)}
        <Swipeable
          onSwiped={eventData => this.trackSwipeDirection(eventData.dir)}
          {...config}
        >
          <div
            className={`explore-slider-container px-64 xxl:px-40 ${
              isOpened ? "opacity-1 visible" : "opacity-0 invisible"
            }`}
          >
            {/* {console.log(isOpened)} */}
            {isOpened ? (
              <div className="xxl:w-9/12 xxl:m-auto flex justify-center h-full overflow-hidden">
                <div className="md:w-full w-4/12 h-full">
                  <ExploreSlider
                    updateSlide={updateSlide}
                    currentSlide={currentSlide}
                    closeLightBox={activateSlider}
                    home={home}
                    swipeDirection={swipeDirection}
                    locale={locale}
                    activePillar={activePillar}
                    queryString={queryString}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </Swipeable>
      </section>
    )
  }
}

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = dispatch => {
  return {
    setActivePillar: pillar => dispatch(setActivePillar(pillar)),
    updateSlide: num => dispatch(updateSlide(num)),
    activateSlider: bool => dispatch(activateSlider(bool)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Explore)
