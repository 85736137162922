import Player from "@vimeo/player"

export const createLocaleTextGetter = languageCode => {
  const languages = [languageCode, "en"] // last language in array is default;
  const localize = value => {
    if (Array.isArray(value)) {
      return value.map(v => localize(v, languages))
    } else if (typeof value == "object") {
      if (/^locale[A-Z]/.test(value._type)) {
        const language = languages.find(lang => value[lang])
        return value[language]
      }
      return Object.keys(value).reduce((result, key) => {
        result[key] = localize(value[key], languages)
        return result
      }, {})
    }
    return value
  }
  return localize
}

// Init Vimeo Player

export const initVimeoPlayer = config => {
  const player =
    typeof window !== "undefined" && window.innerWidth > 768
      ? new Player(config.selector, {
          id: config.desktopVid,
          background: true,
          responsive: true,
          autoplay: true,
          muted: true,
          loop: true,
        })
      : typeof window !== "undefined" && window.innerWidth <= 768
      ? new Player(config.selector, {
          id: config.mobileVid ? config.mobileVid : config.desktopVid,
          background: true,
          responsive: true,
          autoplay: true,
          muted: true,
          loop: true,
        })
      : null

  return player
}

export const initVimeoPlayerWithControl = config => {
  const player =
    typeof window !== "undefined" && window.innerWidth > 768
      ? new Player(config.selector, {
          id: config.desktopVid,
          responsive: true,
          autoplay: true,
          muted: true,
          loop: true,
        })
      : typeof window !== "undefined" && window.innerWidth <= 768
      ? new Player(config.selector, {
          id: config.mobileVid ? config.mobileVid : config.desktopVid,
          responsive: true,
          autoplay: true,
          muted: true,
          loop: true,
        })
      : null

  return player
}
